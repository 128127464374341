
import { Component, Vue } from "vue-property-decorator";
import * as FactcheckService from "@/services/FactCheckService";
import { Partner } from '@/types'
import { ROOT_ACTIONS } from "@/store/actions";

@Component
export default class PartnerIndex extends Vue {
    partners = [] as Partner[]
    selectedPartnerId = '';
    dialogs = {
        disable: false
    }

    loading = false


    openAlertDialog(id: string) {
        this.selectedPartnerId = id
        this.dialogs.disable = true
    }

    cancelEnableToggle() {
        this.partners.find(e => e.id === this.selectedPartnerId)!.enabled = !this.partners.find(e => e.id === this.selectedPartnerId)!.enabled
        this.dialogs.disable = false
        this.selectedPartnerId = ''
    }

    async togglePartner() {
        try {
            this.loading = true;
            await FactcheckService.UpdatePartner({
                id: this.selectedPartnerId,
                enabled: this.partners.find(e => e.id === this.selectedPartnerId)!.enabled
            })
            this.dialogs.disable = false;
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'success',
                text: `Partner ${this.partners.find(e => e.id === this.selectedPartnerId)!.enabled ? 'ingeschakeld' : 'uitgeschakeld'}`
            })
        } catch (error) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    async toggleClaimReviewsEnabled(partnerId: string) {
        try {
            this.loading = true;
            await FactcheckService.UpdatePartner({
                id: partnerId,
                claimReviewsEnabled: this.partners.find(e => e.id === partnerId)!.claimReviewsEnabled
            })
            this.dialogs.disable = false;
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'success',
                text: `Claimreviews ${this.partners.find(e => e.id === partnerId)!.claimReviewsEnabled ? 'ingeschakeld' : 'uitgeschakeld'}`
            })
        } catch (error) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    async mounted() {
        let partners = await FactcheckService.GetPartners(true)
        this.partners = partners.filter(e => e.id !== 'UNKNOWN')
    }
}
